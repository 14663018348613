import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

import CleanHTML from '../cleanHTML';

/**
 * Webinar page Hero component.
 * @component
 *
 * @param   {string} content - Raw html content
 * @param   {string} title   - Page title
 * @returns {component}      - <Hero content={string} title={string} />
 */

const Hero = ({ content, title }) => (
  <div
    className={cx(
      base.flex,
      base.flexColumn,
      base.itemsCenter,
      base.justifyBetween,
      base.w100,
      main.memberWrap
    )}
  >
    <h2
      className={cx(base.mb3, main.memberTitle, main.heading, theme.colorDark)}
    >
      {title}
    </h2>
    <CleanHTML html={content} />
  </div>
);

Hero.propTypes = {
  content: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Hero;
