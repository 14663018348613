import React from 'react';
import PropTypes from 'prop-types';
import { initialize } from 'react-ga';

import Layout from '../components/layout';
import Main from '../components/webinar/webinarMainContent';

/**
 * Webinar page component. Parent component.
 * @component
 *
 * @param   {string} location - URL data object
 * @returns {component}       - <Webinar location={string} />
 */

const Webinar = ({ location }) => {
  initialize('UA-33936956-01', {
    debug: true,
    titleCase: false,
  });

  return (
    <Layout location={location}>
      <Main location={location} />
    </Layout>
  );
};

Webinar.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default Webinar;
