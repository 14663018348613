import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';

import Form from './webinarForm';
import Hero from './webinarHero';

/**
 * Webinar page Main component.
 * @component
 *
 * @returns {component} - <Main />
 */

const Main = () => {
  const {
    contentfulWebinar: {
      copy: {
        childMarkdownRemark: { html },
      },
      formButton,
      formConfirmationCopy,
      formHeading,
      title,
    },
  } = useStaticQuery(graphql`
    query {
      contentfulWebinar {
        copy {
          childMarkdownRemark {
            html
          }
        }
        formButton
        formConfirmationCopy
        formHeading
        title
      }
    }
  `);

  return (
    <section
      className={cx(
        base.flex,
        base.itemsCenter,
        base.justifyCenter,
        base.w100,
        main.contactMain
      )}
    >
      <div
        className={cx(
          base.flex,
          base.itemsCenter,
          base.justifyBetween,
          base.w100,
          main.contactInner
        )}
      >
        <Hero content={html} title={title} />
        <div className={cx(base.h100, main.contactDivider)} />
        <Form
          button={formButton}
          confirmation={formConfirmationCopy}
          heading={formHeading}
        />
      </div>
    </section>
  );
};

export default Main;
